'use client';

import type { PropsWithChildren } from 'react';

import { ThemeProvider, Toaster } from '@/components/radix';

export function ClientProvider({ children }: PropsWithChildren) {
  return (
    <ThemeProvider>
      <Toaster>{children}</Toaster>
    </ThemeProvider>
  );
}
